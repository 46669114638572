<template>
  <b-col>
    <b-form
      ref="form"
      :style="{height: trHeight}"
      class="repeater-form"
      @submit.prevent="repeateAgain"
    >
      <!-- Row Loop -->
      <b-row
        v-for="(item, index) in value"
        :id="item.id"
        :key="item.id"
        ref="row"
      >
        <b-col>
          <rule v-model="value[index]" />
        </b-col>
        <!-- Remove Button -->
        <b-col
          lg="2"
          md="3"
          class="mb-50"
        >
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2"
            @click="removeItem(index)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>Delete</span>
          </b-button>
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>

    </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeateAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add New</span>
    </b-button>
  </b-col>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BFormCheckbox, BForm, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Rule from './Rule.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormCheckbox,
    BForm,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    Rule,

  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],

  props: {
    value: [],

  },
  data() {
    return {
      listaRegole: [],
      nextTodoId: 1,
    }
  },
  mounted() {
    const l = this.value.map(x => ({ id: this.nextTodoId++, regola: x }))
    this.listaRegole = l
  },
  methods: {
    repeateAgain() {
      const r = {
        id: this.nextTodoId += this.nextTodoId,
      }
      this.value.push(r)
      // this.listaRegole.push(r)
      // this.value.push(r)
    },
    removeItem(index) {
      console.log('remove', index)
      this.value.splice(index, 1)
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    /*
    updateValue(event) {
      console.log('event', event)
      this.model.push(event)
      // this.$emit('updateValue', this.listaRegole.map(x => x.regola))
    },
    */
  },

  setup() {

  },
}

</script>

