<template>

  <!-- Table Container Card -->
  <b-overlay
    :show="loading"
    rounded="sm"
  >
    <b-card>
      <h1 v-if="programData.Id">
        Modifica Programma #{{ programData.Id }}
      </h1>
      <h1 v-else>
        Nuovo Programma
      </h1>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col col="12">
            <validation-observer
              #default="{ handleSubmit }"
              ref="ProgramRules"
            >
              <b-form
                aria-disabled="true"
                @submit.prevent="handleSubmit(saveProgram())"
              >
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                        name="CardName"
                      >
                        <b-form-input
                          v-model="programData.Name"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Nome programma"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row
                  v-for="(pk, index) in programData.products"
                  :key="pk.Id"
                >
                  <b-col>
                    <h3 v-if="programData.products[index].baseProduct=='Y'">
                      Prodotto Base
                    </h3>
                    <h3 v-else>
                      Prodotto Secondario
                    </h3>
                    <hr>
                    <b-col>
                      <packages-product
                        v-model="programData.products[index]"
                      />
                    </b-col>
                  </b-col>
                </b-row>
                <b-row><b-col
                  md="12"
                  class="inline-spacing"
                >
                  <b-button
                    class="btn-spacing"
                    @click="addProduct"
                  >
                    + Aggiungi prodotto
                  </b-button>
                  <b-button
                    variant="success"
                    class="btn-spacing"
                    @click="saveProgram"
                  >
                    Salva
                  </b-button>
                </b-col></b-row>
              </b-form>
            </validation-observer></b-col></b-row></div></b-card>
  </b-overlay>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BFormCheckbox, BForm, BFormGroup, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Vue from 'vue'
import programStoreModule from './programStoreModule'
// import useProgramsList from './useProgramList'

import productStoreModule from '../Product/productStoreModule'
import PackagesProduct from './PackagesProduct.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormCheckbox,
    BForm,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    PackagesProduct,
    BOverlay,

  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      loading: true,
      newProgram: {
        Name: "", products: { prodotto: null, pacchetti: [] },
      },
      nextTodoId: 2,
    }
  },
  mounted() {
    this.loading = true
    const { id } = this.$route.params
    if (id) {
      programStoreModule.actions.fetchProgram(null, { id })
        .then(resp => {
          this.programData = resp.data.program
          this.loading = false
        }).catch(err => {
          console.log("err", err)
        })
    } else { this.loading = false }
  },
  created() {
  },
  destroyed() {

  },
  methods: {
    async saveProgram() {
      this.$refs.ProgramRules.validate().then(success => {
        if (success) {
          console.log("Save program", this.programData)
          try {
            this.loading = true
            const data = programStoreModule.actions.AddUpdateProgram(null, this.programData).then(() => {
              this.$swal({
                title: 'Salvato!',
                text: 'Clicca per continuare',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                willClose: () => {
                  this.loading = true
                  this.$router.push({ name: 'programlist' })
                },
                buttonsStyling: false,
              })
            })
          } catch (err) {
            this.loading = false
            this.$swal({
              title: 'Attenzione!',
              text: 'Verifica la correttezza dei dati inseriti e riprova!',
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              willClose: () => {
                this.loading = true
              },
              buttonsStyling: false,
            })
          }
        }
      })

      /*
        .this(resp => {
        // console.log("SAVE", resp.data)
        // this.$router.push({ name: 'programlist' })
        this.$router.push({ path: '/dealer/programs' })
        console.log("End")
      }).catch(err => {
        Vue.prototype.$toast({
          component: ToastificationContent,
          props: {
            title: "Errore durante il salvataggio del Programma",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
        */
    },
    addProduct() {
      console.log('programma', this.programData.products)
      if (!this.programData.products) { this.programData.products = [] }
      this.programData.products.push({ baseProduct: 'N', product: null, packages: [] })
    },
    addEditProgramModal(id) {
      this.$bvModal.show(`add-program-${id}`)
    },
    /*
    async getCompany() {
      return await fetchCompany()
    },
    */
    reset() {
      this.Program = { }
    },
  },
  setup() {
    const PROGRAM_DEALER_STORE_MODULE_NAME = 'app-program'
    const PRODUCT_DEALER_STORE_MODULE_NAME = 'app-product'
    // Register module
    if (!store.hasModule(PROGRAM_DEALER_STORE_MODULE_NAME)) store.registerModule(PROGRAM_DEALER_STORE_MODULE_NAME, programStoreModule)
    if (!store.hasModule(PRODUCT_DEALER_STORE_MODULE_NAME)) store.registerModule(PRODUCT_DEALER_STORE_MODULE_NAME, productStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROGRAM_DEALER_STORE_MODULE_NAME)) store.unregisterModule(PROGRAM_DEALER_STORE_MODULE_NAME)
      if (store.hasModule(PRODUCT_DEALER_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_DEALER_STORE_MODULE_NAME)
    })


    const programData = ref({ Name: "", products: [] })
    const programDetails = ref([])
    const productsList = ref([])
    const productsListSelect = ref([])
    const packageList = ref([])
    const packageListSelect = ref([])

    const fetchProgram = (ctx, callback) => {
      store
        .dispatch(`${PROGRAM_DEALER_STORE_MODULE_NAME}/fetchProgram`, this.$route.params.id)
        .then(response => {
          const { program, details } = response.data
          console.log('program', program)
          programData.value = program
          console.log('programData', this.programData)
          // programDetails.value = details
        })
        .catch(err => {
          // toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Error fetching companies' list",
          //     icon: 'AlertTriangleIcon',
          //     variant: 'danger',
          //   },
          // })
          console.log(err)
          // programDetails.value = []
        })
    }

    const fetchProducts = (ctx, callback) => {
      store
        .dispatch(`${PRODUCT_DEALER_STORE_MODULE_NAME}/fetchProductSelect`)
        .then(response => {
          const { products } = response.data
          // productsListSelect.value = products.map(p => ({ label: p.Name, value: p.Id }))
          productsListSelect.value = products
          productsList.value = products
        })
        .catch(() => {
          Vue.prototype.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching product list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          productsList.value = []
          productsListSelect.value = []
        })
    }

    const fetchPackages = (ctx, callback) => {
      store
        .dispatch(`${PRODUCT_DEALER_STORE_MODULE_NAME}/fetchPackageSelect`)
        .then(response => {
          const { bundles } = response.data
          packageListSelect.value = bundles.map(p => ({ label: p.Name, value: p.Id }))
          packageList.value = bundles
        })
        .catch(() => {
          Vue.prototype.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching package list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          packageList.value = []
          packageListSelect.value = []
        })
    }


    fetchProducts()
    fetchPackages()
    // fetchProgram()
    return {
      fetchProgram,
      programData,
      packageList,
      packageListSelect,
      productsListSelect,
      programDetails,
      productsList,
    }
  },

}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .program-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .btn-spacing {
    margin:0 10px;
  }
  </style>

