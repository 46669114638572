<template>
  <b-row>
    <!-- Campo -->
    <b-col>
      <b-form-group
        label="Campo"
        label-for="field-name"
      >
        <v-select
          v-model="field"
          :options="listaCampi"
        />
      </b-form-group>
    </b-col>
    <!-- confronto -->
    <b-col>
      <b-form-group
        label="Confronto"
        label-for="confronto"
      >
        <v-select
          v-model="action"
          :options="operatoriConfronto"
        />
      </b-form-group>
    </b-col>
    <!-- Valore -->
    <b-col>
      <b-form-group
        label="Valore"
        label-for="Valore"
      >
        <v-select
          v-if="field && field.type=='select'"
          v-model="riferimento"
          :options="field.valori"
        />
        <b-form-input
          v-else
          id="Valore"
          v-model="regola.value"
          type="text"
          placeholder="Valore"
        />
      </b-form-group>
    </b-col>
    <!-- Obbligatorio -->
    <b-col>
      <b-form-group>

        <b-form-checkbox
          v-model="regola.mandatory"
          :checked="regola.mandatory=='Y'"
          name="check-button"
          switch
          inline
          value="Y"
          unchecked-value="N"
        >
          <span v-if="regola.mandatory=='Y'">Obbligatorio</span>
          <span v-else>facoltativo</span>
        </b-form-checkbox>
      </b-form-group>
    </b-col>
    <b-col>
      <b-form-group>

        <b-form-checkbox
          v-model="regola.enabled"
          :checked="regola.enabled=='Y'"
          name="check-button"
          switch
          inline
          value="Y"
          unchecked-value="N"
        >
          <span v-if="regola.enabled=='Y'">Abilitato</span>
          <span v-else>Disabilitato</span>
        </b-form-checkbox>
      </b-form-group>
    </b-col>
  </b-row>


</template>

<script>
import {
  BRow, BCol, BFormInput, BButton, BFormCheckbox, BForm, BFormGroup,
  BFormSelect,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import programStoreModule from './programStoreModule'

export default {
  components: {

    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormCheckbox,
    BForm,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,

  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  model: {
    prop: 'regola',
    event: 'updateValue',
  },
  props: {
    regola: {},

  },
  data() {
    return {
      // regola: { campo: "", confronto: "", valore: "" },
      field: null,
      action: null,
      riferimento: null,
      listaCampi: [
        {
          value: 'valore',
          label: "Valore Auto",
          type: "numerico",
          valori: [],
        },
        {
          value: 'marca',
          label: "Marca Auto",
          type: "select",
          valori: this.loadMarca(),
        },
        {
          value: 'tipo-auto',
          label: "Tipo Auto",
          type: "select",
          valori: [
            { value: 'nuovo', label: "Nuovo" },
            { value: 'usato', label: "Usato" },
          ],
        },
      ],
      operatoriConfronto: [
        { value: '>', label: ">", tipo: "" },
        { value: '>=', label: ">=", tipo: "" },
        { value: '<', label: "<", tipo: "" },
        { value: '<=', label: "<=", tipo: "" },
        { value: '=', label: "=", tipo: "" },
        { value: 'in', label: "in", tipo: "" },
      ],
      valore: [],


    }
  },
  watch: {
    field() {
      if (this.field) { this.regola.field = this.field.value } else { this.regola.field = null }
    },
    action() {
      if (this.action) { this.regola.action = this.action.value } else { this.regola.action = null }
    },
    riferimento() {
      if (this.riferimento) { this.regola.value = this.riferimento.value } else { this.regola.value = null }
    },
  },
  mounted() {
    this.field = this.listaCampi.find(x => x.value == this.regola.field)
    this.action = this.operatoriConfronto.find(x => x.value == this.regola.action)
    if (this.field.type == 'select') { this.riferimento = this.field.valori.find(x => x.value == this.regola.value) }
    // const l = this.model.map(x => ({ id: this.nextTodoId++, regola: x }))
    // this.listaRegole = l
    // if (!this.regola) { this.regola = { campo: "", confronto: "", valore: "" } }
  },
  methods: {
    loadMarca() {
      return ['BMW']
    },
  },

  setup() {

  },
}
</script>

