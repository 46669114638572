<template>
  <b-col>
    <b-form-group
      :label="title"
      label-for="base-product"
    >
      <v-select
        v-if="!value.product"
        v-model="value.product"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :clearable="true"
        :options="listaProdotti"
        class="fixborder"
        label="Name"
        index="Id"
        input-id="base-product"
      />
      <b-row v-else>
        <b-col md="4">
          <h3>{{ value.product.Name }}</h3>
        </b-col>
        <b-col md="4">
          <b-button @click="clearObject">
            Rimuovi
          </b-button>
        </b-col>
        <b-col md="4">
          <b-form-checkbox
            v-model="value.baseProduct"
            name="check-button"
            :checked="value.baseProduct=='Y'"
            switch
            value="Y"
            unchecked-value="N"
          > Prodotto Base
          </b-form-checkbox>
        </b-col>
      </b-row>

    </b-form-group>
    <hr>
    <b-col
      v-for="(pk, Id) in listaPackages"
      v-if="value.product"
      :key="Id"
      md="12"
    >
      <div v-if="pk.product==value.product.Id">
        <b-form-checkbox
          switch
          :checked="value.packages.find(x=>x.package.Id==pk.Id)?pk.Id:0"
          :value="pk.Id"
          :unchecked-value="-pk.Id"
          @change="event=>onChange(event,pk)"
        >
          {{ pk.Id }} - {{ pk.Name }}
        </b-form-checkbox>
        <b-row>
          <b-col
            v-if="value.packages.find(x=>x.package.Id==pk.Id)"
            :key="refresh"
          >

            <RulesOfPackageVue
              :key="refresh"
              v-model="value.packages.find(x=>x.package.Id==pk.Id).rules"
            />
            <hr>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <hr>
  </b-col>

</template>

<script>
import store from '@/store'
import Vue from 'vue'
import {
  BCard, BRow, BCol, BFormInput, BButton, BFormCheckbox, BForm, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  onMounted,
  ref,
} from '@vue/composition-api'

import * as useProductList from '../Product/useProductList'
import * as productStore from '../Product/productStoreModule'
import RulesOfPackageVue from './RulesOfPackage.vue'

const PRODUCT_DEALER_STORE_MODULE_NAME = 'app-product'


export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BFormCheckbox,
    BForm,
    BFormGroup,
    vSelect,
    RulesOfPackageVue,
  },
  props: {
    value: null,
    title: String,
    prodotto: { packagesSelezionati: [] },
  },

  data() {
    return {

      listaPackages: [],
      listaProdotti: [],
      productSelected: null,
      // listaPackagesSelected: [],
      listaRegole: [],
      refresh: true,
    }
  },
  watch: {
    'value.product': 'onChangeSelectedProduct',

  },
  mounted() {
    this.fetchProducts()
    this.fetchPackages()
    this.productSelected = this.prodotto
  },
  setup() {
  },
  methods: {
    onChange(value, pk) {
      if (value > 0) {
        this.value.packages.push({ package: pk, rules: [] })
      } else {
        // this.value.packages.find(x => x.package.Id == pk.Id).rules = null

        this.value.packages = this.value.packages.filter(x => x.package.Id != pk.Id)
        console.log('pk', this.value.packages)
      }
      this.refresh = !this.refresh
    },
    updateValue(event) {
      console.log('updateValue', event)
      this.$emit('input', event.target.value)
    },
    clearObject() {
      this.value.product = null
      this.value.packages = []
    },
    removePackage(event) {
      console.log("remove Package")
      if (event.length == 1) {
        console.log("remove Package", event)
      }
    },
    fetchProducts() {
      console.log('fetchProducts')
      store
        .dispatch(`${PRODUCT_DEALER_STORE_MODULE_NAME}/fetchProductSelect`)
        .then(response => {
          const { products } = response.data
          this.listaProdotti = products
          // this.productSelected = {Id:1, Name:'AAA' }
        })
        .catch(() => {
          Vue.prototype.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching product list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.listaProdotti = []
        })
    },
    fetchPackages() {
      console.log('fetchPackages')
      store
        .dispatch(`${PRODUCT_DEALER_STORE_MODULE_NAME}/fetchPackageSelect`)
        .then(response => {
          const { bundles } = response.data
          this.listaPackages = bundles
        })
        .catch(() => {
          Vue.prototype.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching package list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          this.listaPackages = []
          this.listaPackages = []
        })
    },
    onChangeSelectedProduct(product) {
      console.log('onChangeSelectedProduct', product)
      // this.prodotto = product

      if (product) {
        productStore.default.actions.fetchPackageSelect(null, {})
          .then(response => {
            console.log('response.data', response.data)
            const { bundles } = response.data
            this.listaPackages = bundles.filter(x => x.product == product.Id)
            if (!this.listaPackages) { this.listaPackages = [] }
          })
          .catch(() => {
            Vue.prototype.$toast({
              component: ToastificationContent,
              props: {
                title: "Error fetching package list",
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
            this.listaPackages = []
          })
      } else {
        this.listaPackages = []
      }
    },

  },
}
/*
<b-col
      v-for="(pk, Id) in listaPackages"
      :key="Id"
      md="12"
    >
      {{ pk }}
      <b-form-checkbox
        v-if="prodoto.packagesSelezionati"
        v-model="productSelected.packagesSelezionati"

        name="check-button"
        switch
        :value="pk"
        @change="removePackage"
      >
        {{ pk.Id }} - {{ pk.Name }}
      </b-form-checkbox>
      <hr>
      <b-col v-if="productSelected.packages && productSelected.packages.some(x=>x.Id == pk.Id)">
        {{ listaRegole }}
        <RulesOfPackageVue
          v-model="listaRegole"
        />
      </b-col>
      <hr>
    </b-col>
*/

</script>
